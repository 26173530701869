import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const meta = {
  title: "Intention Martial Arts",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <section className="pt-24 pb-28 bg-white overflow-hidden">
        <div className="container px-4 mx-auto">
          <div className="text-center max-w-lg mx-auto">
            <h2 className="mb-5 text-6xl md:text-7xl font-bold font-heading text-center tracking-px-n leading-tight">
              Learn Self Defense / Martial Arts
            </h2>
            <p className="mb-7 text-lg text-gray-600 font-medium">
              Taekwondo from Intention Martial Arts
            </p>
            <div className="mb-11 md:inline-block">
              <a
                href="https://www.fcpr.us/facilities/recreation-centers/kiwanis"
                className="py-4 px-6 w-full text-white font-semibold border border-indigo-700 rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200"
              >
                Sign Up at Kiwanis Recreation Center
              </a>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
